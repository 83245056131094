import { createVNode, render, h } from "vue";
import FaceVerify from "../faceverify/index.vue";

// 准备div容器
// const container = document.createElement("div");
// container.id = "MESSAGE-ID";
// // 最后将 container 追加到 body 上
// document.body.appendChild(container);

const renderMessage = (_props) => {
  const props = {
    resourceId: null,
    courseId: null,
    studentId: null,
    studyTime: null,
    onConfirmcallback: _props.confirmcallback,
    onCancelcallback: _props.cancelcallback,
  };
  Object.assign(props, _props);

  const container = document.createElement("div");
  container.id = "MESSAGE-ID";
  // 最后将 container 追加到 body 上
  document.body.appendChild(container);

  // 创建 虚拟dom
  const messageVNode = h(FaceVerify, props);
  // 将虚拟dom渲染到 container dom 上
  render(messageVNode, container);

  return messageVNode;
};

let messageInstance = null;

const sendMessage = (message) => {
  console.log("messageInstance", messageInstance);
  if (messageInstance) {
    const messageVue = messageInstance.component;
    messageVue?.exposed?.open?.(message);
  } else {
    messageInstance = renderMessage(message);
    sendMessage(message);
  }
};

const closeMessage = () => {
  if (messageInstance) {
    const messageVue = messageInstance.component;
    messageVue?.exposed?.closeModal();
  }
};

const _message = (message) => {
  sendMessage(message);
};

_message.open = (message) => {
  sendMessage(message);
};

_message.close = () => closeMessage();
// _message.destory = () => {
//   // closeMessage();
//   console.log("-------uninstall---------");
//   messageInstance = null;
//   const dom = document.getElementById("MESSAGE-ID");
//   render(null, dom);
// };

const Message = {
  install: null,
  uninstall: null,
};
// const Message = _message;
Message.install = _message;
Message.uninstall = () => {
  if (messageInstance) {
    messageInstance = null;
    const dom = document.getElementById("MESSAGE-ID");
    render(null, dom);
    document.body.removeChild(dom);
  }
};

export default Message;
