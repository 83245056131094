<template>
  <div class="resource-page" ref="resourceRef">
    <div class="resource-main">
      <div class="resource-title">{{ resource?.resource_name }}</div>
      <div class="resource-content">
        <div class="resource-left">
          <a-spin :spinning="loading">
            <div class="resource-video-box">
              <div v-if="showTips" class="video-tip">
                温馨提示：经省厅要求，本节课看课过程中会随机无感录制一段您看课过程中的视频，请正确着装，正视摄像头，点击查看
                <span @click="openTipsModal()">《隐私协议》</span>，谢谢观看！
              </div>
              <video
                ref="videoRef"
                disableRemotePlayback="true"
                disablepictureinpicture="true"
                playsinline="true"
                :src="resource?.resource_video"
                class="resource-video"
                preload="auto"
                id="classVideo"
                controlslist="nodownload"
              ></video>
              <div class="video-icon" v-if="!isPlay" @click="videoPlay()">
                <caret-right-outlined />
                <!-- <pause-outlined v-else @click="videoPause()" /> -->
              </div>
              <div class="video-icon" v-else @click="videoPause()">
                <!-- <caret-right-outlined @click="videoPlay()" /> -->
                <pause-outlined />
              </div>
            </div>
            <div class="video-tool">
              <!-- <a-tooltip title="3 done / 3 in progress / 4 to do">
                <a-progress
                  strokeColor="#f01400"
                  :percent="60"
                  :show-info="false"
                  size="small"
                  strokeLinecap="square"
                />
              </a-tooltip> -->
              <a-progress
                :stroke-color="'#959595'"
                :percent="videoBuffer"
                :show-info="false"
                size="small"
                strokeLinecap="square"
                :stroke-width="1"
                :success="{ percent: videoProgress, strokeColor: '#f01400' }"
              ></a-progress>
              <div class="video-tool-bottom">
                <div class="play-icon">
                  <caret-right-outlined v-if="!isPlay" @click="videoPlay()" />
                  <pause-outlined v-else @click="videoPause()" />
                </div>
                <div class="video-duration">
                  {{ showAudioTime(videoCurrentTime) }} /
                  {{ showAudioTime(videoDuration) }}
                </div>
                <!-- <div>
                  <a-popover v-model:visible="audioVisible" trigger="click">
                    <template #content>
                      <a @click="hide">
                        <plus-outlined
                          class="play-icon"
                          @click="alterVolume('+')"
                        />
                        <minus-outlined
                          class="play-icon"
                          @click="alterVolume('-')"
                        />
                      </a>
                    </template>
                    <sound-outlined class="play-icon" />
                  </a-popover>
                </div> -->
              </div>
            </div>
          </a-spin>
        </div>
        <div
          :class="{
            'resource-right': true,
            'commit-box-show': showCommit,
            'commit-box-hide': !showCommit
          }"
        >
          <!-- <a-spin :spinning="loading"> -->
          <div class="commit-control-icon" @click="controlCommit()">
            <!-- <arrow-right-outlined class="icon" v-if="showCommit" /> -->
            <close-outlined class="icon" v-if="showCommit" />
            <arrow-left-outlined class="icon" v-else />
          </div>
          <!-- v-if="showCommit" -->
          <ResourceComment
            :visible="showCommit"
            :courseId="courseId"
            :studentId="studentId"
            :comment="courseComment"
            :resourceId="resourceId"
            :course="courseData"
            :courseSection="courseSection"
            :periodDay="record_study_period_day"
            :resourceDay="record_study_resource_day"
          ></ResourceComment>
          <!-- </a-spin> -->
        </div>
      </div>
      <video-tool
        v-model:visible="videoVisible"
        :studentId="studentId"
        :resourceId="resourceId"
        :courseId="courseId"
        :videoTime="videoTimeValue"
        @success="successVideo"
      ></video-tool>
    </div>
    <a-modal
      v-model:visible="showTipsModal"
      :closable="true"
      :centered="true"
      :bodyStyle="{ height: '80vh', overflowY: 'auto' }"
    >
      <template #footer>
        <a-button
          type="primary"
          @click="
            () => {
              showTipsModal = false;
            }
          "
          >确认</a-button
        >
      </template>
      <div style=""></div>
      <div v-html="tipsContent"></div>
    </a-modal>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  watch,
  ref,
  toRefs
} from "vue";
import { getStudentData } from "@/utils/storeData.js";
import { useRoute } from "vue-router";
import {
  getCourseResourceData,
  checkIsOrder,
  startstudy,
  updateCourseRecordData,
  getCourseVerifyRecordList,
  insertCourseStudyRecordData,
  getSettingData,
  studyHour,
  endStudy,
  getCourseData,
  submitRecordVideoUrl,
  thirdFaceCompare,
  checkFacePhoto,
  checkFaceVideo
} from "@/api/main.js";
import { message, Modal, notification } from "ant-design-vue";
import {
  // ArrowRightOutlined,
  ArrowLeftOutlined,
  CaretRightOutlined,
  PauseOutlined,
  CloseOutlined
  // SoundOutlined,
  // MinusOutlined,
  // PlusOutlined,
} from "@ant-design/icons-vue";
import ResourceComment from "./components/ResourceComment.vue";
import { decode } from "js-base64";
import url from "@/utils/url.js";
import FaceVerify from "@/components/library/faceverify.js";
import { getUserData } from "@/utils/storeData.js";
import VideoTool from "@/components/videotool/index.vue";
import { encode } from "js-base64";

export default defineComponent({
  name: "Resource",
  components: {
    ArrowLeftOutlined,
    CaretRightOutlined,
    PauseOutlined,
    ResourceComment,
    CloseOutlined,
    VideoTool
  },
  setup() {
    // window.onresize = () => {
    //   let video = document.getElementById("classVideo");
    //   // video.remove();
    //   pageData.resource=null;
    //   alert("不支持在小窗口播放，请关闭小窗口重新刷新网页重试！");
    // };

    const route = useRoute();
    const resourceRef = ref(null);
    // const
    const pageData = reactive({
      loading: false,
      student: {},
      resource: {},
      showCommit: false,
      isPlay: false, // 视频是否播放中
      courseId: null,
      resourceId: null,
      studentId: null,
      courseComment: [],
      courseData: {},
      courseSection: [],
      videoRef: null,
      videoDuration: null, // 视频总时长
      videoCurrentTime: null, // 视频当前播放时长
      videoCurrentTime_: null,
      videoProgress: 0, // 视频缓冲进度
      videoBuffer: 0, // 视频缓冲进度
      userData: computed(() => getUserData()).value,
      needVerify: false, // 是否需要核验
      record: null,
      showTips: false,
      showTipsModal: false,
      isFirstVerity: false, //首次人脸是否完成
      verifyVideoNum: 0,
      verifyPhotoNum: 0,
      verifyPhotoList: [],
      verifyVideoList: [],
      recordTime: null,
      face_time: [],
      photoNum: 0,
      videoNum: 0,
      record_study_period_day: null,
      record_study_resource_day: null,
      isNeedRecord: false, // 无感知摄像头
      tipsContent: null,
      isRecording: false,
      isCameraing: false,
      isReporting: false,
      isSupervising: false,
      videoVisible: false,
      videoTimeValue: null,
      audioVisible: false, //音量
      faceVerifyVisible: false,
      class_day_limit: null,
      class_day_section: null,
      supplementFace: false
    });

    const getCourseDataFun = (_courseId, _studentId) => {
      return new Promise((resolve, reject) => {
        getCourseData({ course_id: _courseId, student_id: _studentId })
          .then(res => {
            if (res.code == 200) {
              pageData.courseData = res.data.course;
              pageData.courseSection = res.data.course.course_section;
              // console.log("class_day_section", pageData.class_day_section);
              // console.log("class_day_limit", pageData.class_day_limit);
              let _record_study_period_day =
                res.data?.record_study_period_day || 0;
              let _record_study_resource_day =
                res.data?.record_study_resource_day || 0;
              pageData.record_study_period_day = _record_study_period_day;
              pageData.record_study_resource_day = _record_study_resource_day;
              //时长验证、课时验证
              if (
                pageData.class_day_limit &&
                _record_study_period_day >= pageData.class_day_limit
              ) {
                reject({ msg: "今日学习时长已达上限，请明日再来！" });
                return false;
              }

              if (
                pageData.class_day_section &&
                _record_study_resource_day >= pageData.class_day_section
              ) {
                reject({ msg: "今日学习时长已达上限，请明日再来！" });
                return false;
              }
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            reject(res);
          });
      });
    };

    const getCourseVerifyRecordListFun = () => {
      return new Promise((resolve, reject) => {
        getCourseVerifyRecordList({
          student_id: pageData.studentId,
          resource_id: pageData.resourceId,
          course_id: pageData.courseId
        })
          .then(res => {
            if (res.code == 200) {
              pageData.verifyVideoNum = res.data.course_verify_record.filter(
                res => res.record_verify_type == "video"
              ).length;
              pageData.verifyVideoList = res.data.course_verify_record.filter(
                res => res.record_verify_type == "video"
              );
              pageData.verifyPhotoList = res.data.course_verify_record.filter(
                res => res.record_verify_type == "photo"
              );
              pageData.verifyPhotoNum = res.data.course_verify_record.filter(
                res => res.record_verify_type == "photo"
              ).length;
              if (pageData.verifyPhotoNum >= 1) {
                pageData.isFirstVerity = true;
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            reject(res);
            console.log(res);
          });
      });
    };

    // 判断是否在学习时间
    const isDuringDate = (beiginDateStr, endDateStr) => {
      let curDate = new Date();
      let beiginDate = new Date(beiginDateStr);
      let endDate = new Date(endDateStr);
      if (curDate >= beiginDate && curDate <= endDate) {
        return true;
      }
      return false;
    };
    // 250error
    const error250 = (res, _message) => {
      Modal.error({
        title:
          res?.code == 250 ? "系统检测到班级信息已更新，请刷新页面" : _message,
        okText: res?.code == 250 ? "刷新页面" : "确定",
        onOk() {
          if (res?.code == 250) {
            window.location.reload();
          } else {
            window.history.back();
          }
        }
      });
    };

    // 校验权限
    const authResource = (resource_id, student_id) => {
      return new Promise((resolve, reject) => {
        if (pageData.userData.user_isvip == 1) {
          if (student_id) {
            // vip且有studentId 表示此vip看课需传核验记录
            pageData.needVerify = true;
            resolve(student_id);
          } else {
            // vip且无studentId 表示此vip看课不需传核验记录 studentId传null
            pageData.needVerify = false;
            resolve(null);
          }
        } else {
          let class_start_time = pageData.student.class_start_time.replace(
            /-/g,
            "/"
          );
          let class_end_time = pageData.student.class_end_time.replace(
            /-/g,
            "/"
          );
          if (isDuringDate(class_start_time, class_end_time)) {
            checkIsOrder({ resource_id, student_id })
              .then(res => {
                if (res.code == 200) {
                  pageData.needVerify = true;
                  resolve(student_id);
                } else {
                  pageData.needVerify = true;
                  reject(res);
                }
              })
              .catch(res => {
                console.log(res);
                reject(res);
              });
          } else {
            reject({ msg: "当前不在学习时段！" });
          }
        }
      });
    };

    const getCourseResourceDataFun = (student_id, resource_id) => {
      return new Promise((resolve, reject) => {
        getCourseResourceData({ student_id, resource_id })
          .then(res => {
            if (res.code == 200) {
              resolve(res.data.course_resource);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            console.log(res);
            reject(res);
          });
      });
    };

    const controlCommit = () => {
      pageData.showCommit = !pageData.showCommit;
    };

    const showAudioTime = time => {
      let hour = Math.floor(time / 60 / 60);
      let minute =
        hour > 0
          ? Math.floor((time - hour * 60 * 60) / 60)
          : Math.floor(time / 60);
      let second = Math.floor(time % 60);
      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:${second.toString().padStart(2, "0")} `;
    };

    // 视频加载
    const videoLoadedMetaDataFun = course => {
      pageData.videoRef.onloadedmetadata = () => {
        // console.log("duration", pageData.videoRef.duration);
        // console.log("course", course.course_resource_record);
        pageData.videoRef.currentTime =
          course?.course_resource_record?.record_resource_duration || 0;
        // pageData.videoDuration = pageData.videoRef.duration;
      };
    };
    // 缓存条显示
    const videoProgressFun = () => {
      pageData.videoRef.onprogress = () => {
        if (pageData.videoRef.buffered.length > 0) {
          var bufferedEnd = pageData.videoRef.buffered.end(
            pageData.videoRef.buffered.length - 1
          );
          var duration = pageData.videoRef.duration;
          pageData.videoBuffer = (bufferedEnd / duration) * 100;
        }
      };
    };
    const successVerifyFace = () => {
      pageData.verifyPhotoNum++;
      pageData.verifyPhotoList.push(true);
      setTimeout(() => {
        // pageData.isCameraing = false;
        pageData.faceVerifyVisible = false;
      }, 1000);
      console.log("FaceVerify-then");
    };
    const errorVerifyFace = () => {
      console.log("FaceVerify-cancel");
      // pageData.isCameraing = false;
      pageData.faceVerifyVisible = false;
    };
    // 视频进度显示
    const videoTimeUpdateFun = () => {
      pageData.videoRef.ontimeupdate = e => {
        console.log("------ontimeupdate------");
        console.log("currentTime", e.target.currentTime);
        let currentTime = Math.floor(e.target.currentTime);
        pageData.videoCurrentTime = currentTime;
        pageData.videoProgress =
          (pageData.videoRef.currentTime / pageData.videoRef.duration) * 100;
        if (pageData.videoRef) {
          if (pageData.needVerify) {
            // let video = document.getElementById("classVideo");
            // video.addEventListener("playing", function () {
            //   console.log("视频播放中");
            //   if (!pageData.isPlay) {
            //     pageData.resource = null;
            //     Modal.error({
            //       title: "当前播放模式有误，请返回刷新重试！",
            //       onOk() {
            //         window.history.back();
            //       }
            //     });
            //   }
            // });
            // 人脸
            if (
              currentTime % 7 == 0 &&
              currentTime != pageData.videoCurrentTime_ &&
              pageData.isPlay
            ) {
              checkFacePhoto({
                resource_id: pageData.resourceId,
                student_id: pageData.studentId,
                video_study_time: currentTime
              })
                .then(res => {
                  if (res?.code == 201) {
                    console.log("checkFacePhoto", res);
                    videoPause();
                    FaceVerify.install({
                      resourceId: pageData.resourceId,
                      courseId: pageData.courseId,
                      studentId: pageData.studentId,
                      studyTime: currentTime,
                      onConfirmcallback: res => successVerifyFace(res),
                      onCancelcallback: () => errorVerifyFace()
                    });
                  }
                })
                .catch(res => {
                  console.log(res);
                  Modal.error({
                    title: res?.msg || "人脸判断出现错误，请重试" + res,
                    onOk() {
                      window.history.back();
                    }
                  });
                });
            }

            // 开始录像
            if (
              currentTime % 6 == 0 &&
              pageData.verifyVideoNum == 0 &&
              pageData.student.class_video_status == 1 &&
              !pageData.isRecording &&
              pageData.isPlay &&
              currentTime != pageData.videoCurrentTime_
            ) {
              checkFaceVideo({
                resource_id: pageData.resourceId,
                student_id: pageData.studentId,
                video_study_time: currentTime
              })
                .then(res => {
                  if (res.code == 202) {
                    pageData.isRecording = true;
                    pageData.videoTimeValue = res.data?.class_video_value;
                    // videoPause();
                    pageData.videoVisible = true;
                  }
                })
                .catch(res => {
                  console.log("checkFaceVideo", res);
                  Modal.error({
                    title: res?.msg || "人脸判断出现错误，请重试" + res,
                    onOk() {
                      window.history.back();
                    }
                  });
                });
              // console.log("开始录像时间：", pageData.recordTime);
              // if (!pageData.isRecording) {
              //   // videoVerifyTime
              //   pageData.isRecording = true;
              //   pageData.videoVisible = true;
              //   setTimeout(() => {
              //     pageData.isRecording = false;
              //   }, 1000);
              // }
            }
            //上报学习进度
            if (
              currentTime != 0 &&
              currentTime % 10 == 0 &&
              currentTime % 60 != 0 &&
              currentTime != pageData.record.record_resource_duration &&
              pageData.videoCurrentTime_ != currentTime
            ) {
              if (!pageData.isReporting) {
                pageData.isReporting = true;
                console.log("上报学习进度", currentTime);
                updateCourseRecordDataFun(currentTime)
                  .then(() => {
                    return insertCourseStudyRecordDataFun(10);
                  })
                  .then(() => {
                    setTimeout(() => {
                      pageData.isReporting = false;
                    }, 1000);
                  })
                  .catch(res => {
                    console.log(res);
                    error250(res, res?.msg || "更新进度有误，请稍后再试" + res);
                    // message.error(res?.msg || "更新进度有误，请稍后再试");
                    if (pageData.userData?.user_isvip != 1) {
                      videoPause();
                    }
                  });
              }
            }
            //监管上报学习进度
            if (
              currentTime != 0 &&
              currentTime % 60 == 0 &&
              currentTime != pageData.record.record_resource_duration &&
              !pageData.isSupervising &&
              pageData.videoCurrentTime_ != currentTime
            ) {
              pageData.isSupervising = true;
              jgStudyHourFun(currentTime)
                .then(() => {
                  return updateCourseRecordDataFun(currentTime);
                })
                .then(() => {
                  return insertCourseStudyRecordDataFun(10);
                })
                .then(() => {
                  setTimeout(() => {
                    pageData.isSupervising = false;
                  }, 1000);
                })
                .catch(res => {
                  console.log(res);
                  if (pageData.userData?.user_isvip != 1) {
                    videoPause();
                  }
                  let text = "";
                  if (res?.code == 251) {
                    text = "监管平台上传数据有误(Code10002)，请重试";
                  } else {
                    text = res?.msg;
                  }
                  error250(res, text || "监管上报学习进度有误，请稍后再试");
                });
            }
          }
          pageData.videoCurrentTime_ = currentTime;
        }
      };
    };

    // 学习结束成功弹窗 自动播放下一个视频
    const endTips = () => {
      let nextResource = {};
      let resourceIndex = null,
        sectionId = null;
      pageData.courseSection?.map((i, ii) => {
        i?.course_resource?.map((j, jj) => {
          if (j.resource_id == pageData.resourceId) {
            resourceIndex = jj;
            sectionId = ii;
          }
        });
      });
      if (pageData.courseSection[sectionId]?.course_resource.length > 0) {
        if (
          pageData.courseSection[sectionId]?.course_resource[resourceIndex + 1]
        ) {
          nextResource =
            pageData.courseSection[sectionId]?.course_resource[
              resourceIndex + 1
            ];
        } else {
          nextResource =
            pageData.courseSection[sectionId + 1]?.course_resource[0];
        }
      }
      // console.log("courseSection", pageData.courseSection);
      // console.log("nextResource", nextResource);
      if (nextResource?.resource_id) {
        notification.success({
          message: "恭喜你，完成本节课的学习！",
          description: "即将自动播放下一个视频，请稍后",
          duration: 3,
          onClose: () => {
            url.commentNavigater(
              "/course/resource",
              {
                t: encode(
                  `${nextResource.resource_id},${pageData.courseId},${pageData.studentId}`
                )
              },
              pageData.studentId,
              nextResource?.course_resource_record?.record_resource_per,
              pageData.record_study_period_day,
              pageData.record_study_resource_day,
              nextResource.resource_id
            );
          }
        });
      } else {
        Modal.success({
          title: "您已学完本课程所有内容",
          onOk() {
            window.history.back();
          }
        });
      }
    };
    // 学习结束
    const videoEnd = () => {
      pageData.videoRef.onended = () => {
        console.log("学习结束");
        if (pageData.needVerify) {
          jgStudyHourFun(pageData.videoDuration)
            .then(() => {
              return endStudyFun();
            })
            .then(() => {
              return insertCourseStudyRecordDataFun(
                pageData.videoDuration -
                  Math.floor(pageData.videoDuration / 10) * 10
              );
            })
            .then(() => {
              return updateCourseRecordDataFun(pageData.videoDuration);
            })
            .then(() => {
              endTips();
            })
            .catch(res => {
              console.log(res);
              let text = "";
              if (res?.code == 251) {
                text = "监管平台上传数据有误(Code10003)，请重试";
              } else {
                text = res?.msg;
              }
              error250(res, text || "结束学习有误");
            });
        } else {
          endTips();
        }
      };
    };
    // 初始化视频
    const initVideo = course => {
      // console.log('course', course);
      // 判断浏览器是否支持html 设置自定义控件
      let a = !!pageData.videoRef.canPlayType;
      if (a) {
        // console.log("videoRef", pageData.videoRef);
        pageData.videoRef.controls = false; // 禁止视频控制器
        if (pageData.student_id) {
          pageData.videoRef.currentTime =
            pageData.record.record_resource_duration;
        }
        // pageData.videoRef.controls = true; // 方便测试
        // 视频加载
        videoLoadedMetaDataFun(course);
        // 缓存条显示
        videoProgressFun();
        // 视频进度显示
        videoTimeUpdateFun();
        // 视频结束
        videoEnd();
        pageData.videoRef.onstalled = event => {
          console.log("onstalled", event);
        };
        pageData.videoRef.onwaiting = event => {
          console.log("onwaiting", event);
        };
        pageData.videoRef.onsuspend = event => {
          console.log("onsuspend", event);
        };
        pageData.videoRef.onplaying = event => {
          console.log("onplaying", event);
          if (!pageData.isPlay) {
            // window.history.back();
            pageData.resource = null;
            Modal.error({
              title: "当时播放模式不支持，请返回刷新重试！",
              onOk() {
                window.history.back();
              }
            });
            return;
          }
        };
        pageData.videoRef.onemptied = event => {
          console.log("onemptied", event);
        };
      }
      setTimeout(() => {
        pageData.loading = false;
      }, 800);
    };

    watch(
      () => route.query,
      val => {
        // console.log("val", decode(val.t).split(","));
        if (route.path == "/course/resource" && val.t) {
          pageData.loading = true;
          let _query = decode(val.t).split(",");
          // console.log("_query", _query);
          pageData.resourceId = _query[0];
          pageData.courseId = _query[1];
          pageData.studentId = _query[2] || "";
          // 获取课程配置
          getStudentData()
            .then(res => {
              if (pageData.studentId) {
                pageData.student = res.filter(
                  res1 => res1.student_id == pageData.studentId
                )[0];
              } else {
                pageData.student = null;
              }
              // console.log("pageData.student", pageData.student);
              pageData.class_day_limit =
                pageData.student?.class_day_limit || null;
              pageData.class_day_section =
                pageData.student?.class_day_section || null;
              // 校验权限
              return authResource(_query[0], _query[2]);
            })
            .then(res => {
              return getCourseDataFun(pageData.courseId, pageData.studentId);
            })
            .then(res => {
              // 资源信息
              return getCourseResourceDataFun(
                pageData.studentId,
                pageData.resourceId
              );
            })
            .then(res => {
              pageData.resource = res;
              pageData.showCommit = true;
              pageData.courseComment = res.course_comment;
              pageData.videoDuration = res.resource_duration;
              if (pageData.needVerify) {
                pageData.record = res.course_resource_record;
                pageData.videoCurrentTime =
                  res?.course_resource_record?.record_resource_duration;
                pageData.videoRef.currentTime =
                  res?.course_resource_record?.record_resource_duration;
                pageData.videoTimeValue = pageData.student?.class_video_value;
              }
              // 获取核验记录
              return getCourseVerifyRecordListFun();
            })
            .then(() => {
              if (pageData.needVerify) {
                if (
                  pageData.student.class_hangup_status == 2 ||
                  pageData.student.class_start_veriface_status == 1 ||
                  pageData.student.class_video_status == 1
                ) {
                  if (
                    !navigator.mediaDevices &&
                    !navigator.mediaDevices?.getUserMedia
                  ) {
                    navigator.userMedia =
                      navigator.getUserMedia ||
                      navigator.mozGetUserMedia ||
                      navigator.webkitGetUserMedia ||
                      navigator.mozGetUserMedia ||
                      navigator.msGetUserMedia ||
                      navigator.oGetUserMedia;
                    if (!navigator.userMedia) {
                      // alert("浏览器不支持拍照，请更换浏览器！");
                      Modal.error({
                        title: "浏览器不支持拍照，请更换浏览器！",
                        onOk() {
                          window.history.back();
                        }
                      });
                      return false;
                    }
                    navigator.userMedia(
                      { video: true, audio: true },
                      stream => {
                        // console.log("已点击允许,开启成功");
                        stream.getTracks()[0].stop();
                        stream.getTracks()[1].stop();
                      },
                      err => {
                        if (
                          err.name == "NotAllowedError" &&
                          err.message == "Permission denied"
                        ) {
                          Modal.error({
                            title: "请打开摄像头权限",
                            okText: "返回",
                            onOk() {
                              window.history.back();
                            }
                          });
                        } else {
                          Modal.error({
                            title:
                              "摄像头调取失败:" + err?.name + ":" + err.message,
                            okText: "返回",
                            onOk() {
                              window.history.back();
                            }
                          });
                        }
                      }
                    );
                  }

                  if (
                    navigator.mediaDevices &&
                    navigator.mediaDevices.getUserMedia
                  ) {
                    navigator.mediaDevices
                      .getUserMedia({ video: true, audio: true })
                      .then(stream => {
                        console.log("已点击允许,开启成功");
                        stream.getTracks()[0].stop();
                        stream.getTracks()[1].stop();
                      })
                      .catch(err => {
                        if (
                          err.name == "NotAllowedError" &&
                          err.message == "Permission denied"
                        ) {
                          Modal.error({
                            title: "请打开摄像头权限",
                            okText: "返回",
                            onOk() {
                              window.history.back();
                            }
                          });
                        } else {
                          Modal.error({
                            title:
                              "摄像头调取失败:" + err?.name + ":" + err.message,
                            okText: "返回",
                            onOk() {
                              window.history.back();
                            }
                          });
                        }
                      });
                  }
                }

                //确定开始录像时间
                if (pageData.student.class_video_status == 1) {
                  let class_video_type = pageData.student.class_video_type;
                  // pageData.ctx = wx.createCameraContext();
                  pageData.showTips = true;
                  switch (class_video_type) {
                    case 1:
                      pageData.recordTime = 7;
                      break;
                    case 2:
                      pageData.recordTime = Math.floor(
                        pageData.videoDuration / 4
                      );
                      break;
                    case 3:
                      pageData.recordTime = Math.floor(
                        pageData.videoDuration / 3
                      );
                      break;
                    case 4:
                      pageData.recordTime = Math.floor(
                        pageData.videoDuration / 2
                      );
                      break;
                    default:
                      pageData.recordTime = null;
                      break;
                  }
                }
                if (
                  pageData.record.record_resource_duration >=
                    Math.floor(pageData.recordTime) &&
                  pageData.verifyVideoNum == 0
                ) {
                  pageData.recordTime =
                    pageData.record.record_resource_duration + 1;
                }

                //防挂机配置
                if (pageData.student.class_hangup_status == 2) {
                  let class_hangup_type = pageData.student.class_hangup_type;
                  switch (class_hangup_type) {
                    case 0:
                      let s = Math.floor(
                        (pageData.videoDuration - 10) /
                          pageData.student.class_hangup_value
                      );
                      for (
                        var i = 1;
                        i <= pageData.student.class_hangup_value;
                        i++
                      ) {
                        pageData.face_time.push(s * i);
                      }
                      break;
                    case 1:
                      pageData.face_time.push(
                        pageData.student.class_hangup_value * 60
                      );
                      break;
                    case 2:
                      let s1 = Math.floor(
                        pageData.videoDuration /
                          (pageData.student.class_hangup_value * 60)
                      );
                      for (var i = 1; i <= s1; i++) {
                        pageData.face_time.push(
                          pageData.student.class_hangup_value * 60 * i
                        );
                      }
                      break;
                    default:
                      pageData.face_time = [];
                      break;
                  }
                  console.log("pageData.face_time", pageData.face_time);
                  if (pageData.face_time.includes(pageData.recordTime)) {
                    pageData.recordTime = Number(pageData.recordTime) + 2;
                  }
                  //获取当前时间应存在的照片数量和视频数量
                  for (let i = 0; i <= pageData.face_time.length; i++) {
                    if (pageData.videoCurrentTime >= pageData.face_time[i]) {
                      pageData.photoNum++;
                    }
                  }
                  // console.log("pageData.photoNum", pageData.photoNum);
                  if (pageData.student.class_start_veriface_status == 1) {
                    pageData.photoNum++;
                    pageData.face_time.unshift(0);
                  }
                  if (pageData.videoCurrentTime >= pageData.recordTime) {
                    pageData.videoNum = 1;
                  }
                }
              }
              nextTick(() => {
                initVideo(pageData.resource);
              });
            })
            .catch(res => {
              console.log(res);
              pageData.loading = true;
              if (res?.msg == "您无权观看此课程") {
                Modal.error({
                  title: "您无权观看此课程",
                  okText: "返回首页",
                  onOk() {
                    url.replaceTo("/index");
                  }
                });
              }
              // else if (res?.msg == "请按照顺序学习") {
              //   Modal.error({
              //     title: "请按照顺序学习",
              //     okText: "返回",
              //     onOk() {
              //       window.history.back();
              //     },
              //   });
              // }
              else {
                Modal.error({
                  title: res?.msg || "网络有误，请稍后再试",
                  okText: "返回",
                  onOk() {
                    window.history.back();
                  }
                });
                // message.error(res?.msg || "网络有误，请稍后再试");
              }
            });
        }
      },
      { immediate: true }
    );

    // 监听页面离开
    document.onvisibilitychange = () => {
      console.log("visibilitychange", document.visibilityState);
      if (document.visibilityState == "hidden") {
        videoPause();
      }
    };
    window.onblur = function () {
      console.log("onblur");
      videoPause();
    };
    //监管-开始学习提交
    const startStudyFun = (resource_id, student_id) => {
      return new Promise((resolve, reject) => {
        startstudy({ resource_id, student_id })
          .then(res => {
            if (!res) {
              resolve(true);
            } else if (res?.code == 200) {
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            console.log(res);
            reject(res);
          });
      });
    };
    //监管-结束学习提交
    const endStudyFun = () => {
      return new Promise((resolve, reject) => {
        endStudy({
          resource_id: pageData.resourceId,
          student_id: pageData.studentId
        })
          .then(res => {
            if (res?.code == 200) {
              resolve(res);
            } else if (!res) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            console.log(res);
            reject(res);
          });
      });
    };
    //监管-课程进度提交
    const jgStudyHourFun = play_time => {
      return new Promise((resolve, reject) => {
        studyHour({
          resource_id: pageData.resourceId,
          student_id: pageData.studentId,
          play_time: play_time
        })
          .then(res => {
            if (!res) {
              resolve(true);
            } else if (res?.code == 200) {
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            console.log(res);
            reject(res);
          });
      });
    };

    // 首次人脸
    const firstFaceFun = () => {
      if (
        pageData.student.class_start_veriface_status == 1 &&
        !pageData.isFirstVerity
      ) {
        //跳转到人脸识别页面，视频暂停，识别通过后返回，需用户点继续播放
        console.log("首次人脸认证");
        videoPause();
        pageData.faceVerifyVisible = true;
        FaceVerify.install({
          resourceId: pageData.resourceId,
          courseId: pageData.courseId,
          studentId: pageData.studentId,
          studyTime: 0,
          onConfirmcallback: res => {
            pageData.verifyPhotoNum++;
            pageData.isFirstVerity = true;
            pageData.verifyPhotoList.push(true);
            setTimeout(() => {
              pageData.faceVerifyVisible = false;
            }, 1000);
            console.log("FaceVerify-then", res);
          },
          onCancelcallback: () => {
            pageData.faceVerifyVisible = false;
            console.log("FaceVerify-cancel");
          }
        });
      }
    };

    // 播放视频
    const videoPlay = () => {
      console.log("==========videoPlay==========");
      if (
        pageData.videoRef &&
        (pageData.videoRef.paused || pageData.videoRef.ended)
      ) {
        if (pageData.needVerify) {
          if (pageData.record.record_resource_duration == 0) {
            startStudyFun(pageData.resourceId, pageData.studentId)
              .then(res => {
                pageData.record.record_resource_duration = 1;
                console.log("更新学习进度", res);
                // firstFaceFun();
                pageData.videoRef.play();
                pageData.isPlay = true;
                pageData.isCameraing = false;
                pageData.faceVerifyVisible = false;
              })
              .catch(res => {
                console.log(res);
                videoPause();
                let text = "";
                if (res?.code == 251) {
                  text = "监管平台上传数据有误(Code10001)，请重试";
                } else {
                  text = res?.msg;
                }
                error250(res, text || "开始学习有误，请重试");
              });
          } else {
            pageData.videoRef.play();
            pageData.isPlay = true;
            pageData.isCameraing = false;
            pageData.faceVerifyVisible = false;
          }
        } else {
          pageData.videoRef.play();
          pageData.isPlay = true;
          pageData.isCameraing = false;
          pageData.faceVerifyVisible = false;
        }
      }
    };
    // 暂停视频
    const videoPause = () => {
      console.log("----------------videoPause----------------");
      // console.log("currentTime", pageData.videoRef.currentTime);
      // console.log("videoCurrentTime_", pageData.videoCurrentTime_);
      // console.log("isCameraing", pageData.isCameraing);
      // console.log("faceVerifyVisible", pageData.faceVerifyVisible);
      if (
        pageData.videoRef &&
        !pageData.videoRef?.paused &&
        !pageData.videoRef?.ended
      ) {
        pageData.videoRef?.pause();
        pageData.isPlay = false;
        // pageData.videoRef.currentTime =
        // Math.floor(pageData.videoRef.currentTime) + 0.9999;
        pageData.videoRef.currentTime = Math.floor(
          pageData.videoRef.currentTime
        );
      }
    };
    // 控制音量
    // const alterVolume = (dir) => {
    //   const currentVolume = Math.floor(pageData.videoRef.volume * 10) / 10;
    //   let _volume = pageData.videoRef.volume;
    //   if (dir === "+" && currentVolume < 1) {
    //     _volume += 0.1;
    //     pageData.videoRef.volume = _volume.toFixed(1);
    //   } else if (dir === "-" && currentVolume > 0) {
    //     _volume -= 0.1;
    //     pageData.videoRef.volume = _volume.toFixed(1);
    //   }
    //   console.log("pageData.videoRef.volume", pageData.videoRef.volume);
    // };

    //更新学习进度 ,开始，结束，每分钟调用
    const updateCourseRecordDataFun = record_resource_duration => {
      return new Promise((resolve, reject) => {
        updateCourseRecordData({
          resource_id: pageData.resourceId,
          course_id: pageData.courseId,
          student_id: pageData.studentId,
          record_resource_duration,
          resource_duration: pageData.videoDuration
        })
          .then(res => {
            if (res.code == 200) {
              resolve(res);
            } else {
              console.log("updateCourseRecordData1", res);
              reject(res);
            }
          })
          .catch(res => {
            console.log("updateCourseRecordData2", res);
            reject(res);
          });
      });
    };

    // 新增学习时长记录,退出页面时调用，每1分钟调用
    const insertCourseStudyRecordDataFun = record_study_period => {
      return new Promise((resolve, reject) => {
        insertCourseStudyRecordData({
          resource_id: pageData.resourceId,
          course_id: pageData.courseId,
          student_id: pageData.studentId,
          record_study_period
        })
          .then(res => {
            if (res.code == 200) {
              let record_study_period_day = res.data.record_study_period_day;
              pageData.record_study_period_day = record_study_period_day;
              if (pageData.student.class_day_limit != 0) {
                if (
                  record_study_period_day >= pageData.student.class_day_limit
                ) {
                  //每日学习时长已达上限，视频停止
                  videoPause();
                  reject({
                    code: 400,
                    msg: "今日学习时长已达上限，请明日再来！"
                  });
                  return false;
                }
              }
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch(res => {
            console.log(res);
            reject(res);
          });
      });
    };

    const openTipsModal = () => {
      videoPause();
      if (!pageData.tipsContent) {
        getSettingData({ setting_id: 1 })
          .then(res => {
            if (res.code == 200) {
              pageData.tipsContent = res.data.setting.setting_content;
              pageData.showTipsModal = true;
            } else {
              message.error(res.msg);
            }
          })
          .catch(res => {
            console.log(res);
            message.error(res?.msg || "网络有误，请稍后再试");
          });
      } else {
        pageData.showTipsModal = true;
      }
    };

    //  人脸视频上传成功
    const successVideo = () => {};

    return {
      ...toRefs(pageData),
      controlCommit,
      videoPlay,
      videoPause,
      openTipsModal,
      showAudioTime,
      resourceRef,
      // alterVolume,
      successVideo
    };
  },
  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    if (this.faceVerifyVisible) {
      this.faceVerifyVisible = false;
    }
    FaceVerify.install.close();
    FaceVerify.uninstall();
    this.videoPause();
    Modal.destroyAll();
    notification.destroy();
    message.destroy();
    if (this.videoRef) {
      this.videoRef.currentTime = 0;
    }
    this.videoRef = null;
    let initData = {
      loading: false,
      student: {},
      resource: {},
      showCommit: false,
      isPlay: false, // 视频是否播放中
      courseId: null,
      resourceId: null,
      studentId: null,
      courseComment: [],
      courseData: {},
      courseSection: [],
      videoRef: null,
      videoDuration: null, // 视频总时长
      videoCurrentTime: null, // 视频当前播放时长
      videoCurrentTime_: null,
      videoProgress: 0, // 视频缓冲进度
      videoBuffer: 0, // 视频缓冲进度
      userData: computed(() => getUserData()).value,
      needVerify: false, // 是否需要核验
      record: null,
      showTips: false,
      showTipsModal: false,
      isFirstVerity: false, //首次人脸是否完成
      verifyVideoNum: 0,
      verifyPhotoNum: 0,
      recordTime: null,
      face_time: [],
      photoNum: 0,
      videoNum: 0,
      record_study_period_day: null,
      record_study_resource_day: null,
      isNeedRecord: false, // 无感知摄像头
      tipsContent: null,
      isRecording: false,
      isCameraing: false,
      isReporting: false,
      isSupervising: false,
      videoVisible: false,
      videoTimeValue: null,
      audioVisible: false, //音量
      faceVerifyVisible: false,
      class_day_limit: null,
      class_day_section: null,
      supplementFace: false
    };
    Object.assign(this, JSON.parse(JSON.stringify(initData)));
    next();
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    this.videoPause();
    // this.videoRef = null;
    // this.isPlay = false;
    // FaceVerify.close();
    if (this.faceVerifyVisible) {
      this.faceVerifyVisible = false;
    }
    FaceVerify.install.close();
    FaceVerify.uninstall();
    // this.faceVerifyVisible = false;
    Modal.destroyAll();
    if (this.videoRef) {
      this.videoRef.currentTime = 0;
    }
    this.videoRef = null;
    let initData = {
      loading: false,
      student: {},
      resource: {},
      showCommit: false,
      isPlay: false, // 视频是否播放中
      courseId: null,
      resourceId: null,
      studentId: null,
      courseComment: [],
      courseData: {},
      courseSection: [],
      videoRef: null,
      videoDuration: null, // 视频总时长
      videoCurrentTime: null, // 视频当前播放时长
      videoCurrentTime_: null,
      videoProgress: 0, // 视频缓冲进度
      videoBuffer: 0, // 视频缓冲进度
      userData: computed(() => getUserData()).value,
      needVerify: false, // 是否需要核验
      record: null,
      showTips: false,
      showTipsModal: false,
      isFirstVerity: false, //首次人脸是否完成
      verifyVideoNum: 0,
      verifyPhotoNum: 0,
      recordTime: null,
      face_time: [],
      photoNum: 0,
      videoNum: 0,
      record_study_period_day: null,
      record_study_resource_day: null,
      isNeedRecord: false, // 无感知摄像头
      tipsContent: null,
      isRecording: false,
      isCameraing: false,
      isReporting: false,
      isSupervising: false,
      videoVisible: false,
      videoTimeValue: null,
      audioVisible: false, //音量
      faceVerifyVisible: false,
      class_day_limit: null,
      class_day_section: null,
      supplementFace: false
    };
    Object.assign(this, JSON.parse(JSON.stringify(initData)));
    console.log("this", this);
    next();
  }
});
</script>

<style lang="less">
.resource-page {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
  background-color: #1c1f21;
  .resource-main {
    // max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 50px;
    // padding-top: 20px;
    // background: #ddd;
    .resource-title {
      //   background: #fff;
      color: #fff;
      padding: 16px 0 16px 20px;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid rgba(28, 31, 33, 0.1);
      border-radius: 8px 8px 0 0;
      background-color: #1c1f21;
    }
    .resource-content {
      display: flex;
      height: calc(100% - 57px);
      .resource-left {
        flex: 1 1 auto;
        background: #070c11;
        .resource-video-box {
          width: 100%;
          height: calc(100vh - 171px);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .video-tip {
            position: absolute;
            top: 0;
            color: #fff;
            background: #00000080;
            width: 100%;
            text-align: center;
            padding: 8px 0;
            z-index: 6;
            span {
              color: #1890f1;
              cursor: pointer;
            }
          }
          .resource-video {
            width: 100%;
            // height: 100%;
            height: 100%;
            object-fit: contain;
          }
          .video-icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #ffffff80;
            box-shadow: 0px 2px 10px 0 #07111b66;
            position: absolute;
            bottom: 10px;
            right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            visibility: hidden;
            cursor: pointer;
          }
          &:hover {
            .video-icon {
              visibility: visible;
            }
          }
        }
        .video-tool {
          background-color: #070c11;
          height: 60px;
          .video-tool-bottom {
            padding: 0 20px;
            display: flex;
            align-items: center;
            .play-icon {
              color: #f01400;
              font-size: 20px;
              cursor: pointer;
              margin-right: 20px;
            }
            .video-duration {
              color: #787d82;
            }
          }
        }
      }
      .resource-right {
        position: relative;
        height: 100%;
        overflow-y: auto;
        border-left: 1px solid rgba(28, 31, 33, 0.1);
        .commit-control-icon {
          // background: #ffffff80;
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          cursor: pointer;
          color: #93999e;
          font-size: 20px;
          // border-radius: 50%;
          // box-shadow: 0px 2px 10px 0 #0000009c;
        }
      }
      .commit-box-show {
        flex: 0 0 350px;
        // width: 350px;
        background: #26292c;
        .commit-control-icon {
          top: 0;
          right: 10px;
          // top: 50%;
          // transform: translateY(-50%);
          // left: -20px;
          // box-shadow: 0 8px 16px 0 #07111b1a;
          // border-radius: 50%;
        }
      }
      .commit-box-hide {
        flex: 0 0 50px;
        // width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .commit-control-icon {
        }
      }
    }
  }
}
</style>
