<template>
  <div v-if="visible" class="resource-right-content">
    <div class="resource-right-content-tabs">
      <div
        :class="{ 'resource-tab': true, 'resource-tab-active': activeTab == 0 }"
        @click="chooseTabs(0)"
      >
        目录
      </div>
      <div
        :class="{ 'resource-tab': true, 'resource-tab-active': activeTab == 1 }"
        @click="chooseTabs(1)"
      >
        评论
      </div>
    </div>
    <div class="resource-right-content-main">
      <div v-if="activeTab == 0" class="resource-course-section-box">
        <div
          v-if="courseSection.length == 0"
          class="resource-course-section-item"
        >
          <a-empty description="暂无章节"></a-empty>
        </div>
        <template v-for="(item, index) in courseSection" :key="index">
          <div class="resource-course-section-item">
            <div class="resource-course-section-title">
              {{ item.section_name }}
            </div>
            <div class="resource-course-resource-box">
              <template
                v-for="(item2, index2) in item.course_resource"
                :key="index2"
              >
                <div
                  :class="{
                    'resource-resource-title': true,
                    flex: true,
                    'resource-resource-title-active':
                      resourceId == item2.resource_id,
                  }"
                  @click="goToResource(item2)"
                >
                  <!-- @click="goToResource(item2)" -->
                  <video-camera-outlined
                    style="font-size: 18px; margin-right: 8px"
                  />
                  <div class="resource-resource-title-text">
                    {{ item2.resource_name }}
                  </div>
                  <div>({{ getTime(item2.resource_duration) }})</div>
                </div>
              </template>
              <a-empty
                v-if="item.course_resource.length == 0"
                description="暂无资源"
              ></a-empty>
            </div>
          </div>
        </template>
      </div>
      <div v-if="activeTab == 1" class="resource-course-section-box">
        <div class="resource-comment-top">
          <template v-for="item in courseComment" :key="item">
            <div class="comment-box">
              <img :src="item.user_avatar" alt="" class="user-avatar" />
              <div class="comment-info">
                <div class="user-name">{{ item.user_name }}</div>
                <div class="comment-time">{{ item.comment_time }}</div>
                <div class="comment-content">{{ item.comment_content }}</div>
              </div>
            </div>
          </template>
          <a-empty
            v-if="courseComment.length == 0"
            description="暂无评论"
          ></a-empty>
        </div>
        <div class="resource-comment-bottom">
          <a-input
            v-model:value="user_comment"
            placeholder="评论一下~"
          ></a-input>
          <send-outlined class="comment-icon" @click="sendComment()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from "vue";
import { defineComponent, reactive } from "vue-demi";
import { insertCourseCommentData } from "@/api/main.js";
import { VideoCameraOutlined, SendOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getUserId } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { encode } from "js-base64";

export default defineComponent({
  name: "ResourceCommit",
  components: { VideoCameraOutlined, SendOutlined },
  props: {
    courseId: {
      default: null,
    },
    studentId: {
      default: null,
    },
    resourceId: {
      default: null,
    },
    visible: {
      default: false,
    },
    comment: {
      default: [],
    },
    courseData: {
      default: {},
    },
    courseSection: {
      default: [],
    },
    resourceDay: {
      default: null,
    },
    periodDay: {
      default: null,
    },
  },
  setup(props) {
    const pageData = reactive({
      activeTab: 0,
      oldValue: [],
      courseComment: computed(() => props.comment),
      user_comment: null,
      userId: computed(() => getUserId()),
    });
    const chooseTabs = (index) => {
      pageData.activeTab = index;
    };

    const getTime = (_time) => {
      let time = "";
      let hours = _time > 3600 ? Math.floor(_time / 3600) : 0;
      let minutes = _time > 60 ? Math.floor((_time % 3600) / 60) : 0;
      let second = (_time % 3600) % 60;
      time = `${hours.toString().padStart(2, 0)}:${minutes
        .toString()
        .padStart(2, 0)}:${second.toString().padStart(2, 0)}`;
      return time;
    };

    const sendComment = () => {
      if (!pageData.user_comment) {
        message.error("请输入评论");
        return false;
      }
      insertCourseCommentData({
        resource_id: props.resourceId,
        user_id: pageData.userId,
        comment_content: pageData.user_comment,
      })
        .then((res) => {
          if (res.code == 200) {
            message.success("发送成功");
            pageData.user_comment = null;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };

    const goToResource = (item) => {
      // console.log("item1", item);
      url.commentNavigater(
        "/course/resource",
        {
          t: encode(`${item.resource_id},${props.courseId},${props.studentId}`),
        },
        props.studentId,
        item?.course_resource_record?.record_resource_per,
        props.periodDay,
        props.resourceDay,
        item.resource_id
      );
    };

    return {
      ...toRefs(pageData),
      chooseTabs,
      getTime,
      sendComment,
      goToResource,
    };
  },
});
</script>

<style lang="less" scoped>
.resource-right-content {
  padding-left: 10px;
  color: #fff;
  height: 100%;
  overflow-y: hidden;
  &-tabs {
    display: flex;
    .resource-tab {
      padding: 10px 20px;
      position: relative;
      cursor: pointer;
    }
    .resource-tab-active::after {
      content: "";
      display: block;
      width: 60%;
      height: 2px;
      border-radius: 1px;
      background-color: #f01400;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-main {
    height: calc(100% - 41px);
    .resource-course-section-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 10px 20px 10px 0;
      position: relative;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: #26292c;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #4e5a5e;
      }
      .resource-course-section-title {
        font-size: 14px;
        padding: 10px 0;
        margin-bottom: 12px;
      }
      .resource-resource-title-active {
        color: #f01400;
      }
      .resource-course-resource-box {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        padding-left: 12px;
        margin-bottom: 16px;
        .resource-resource-title:hover {
          color: #f01400;
          cursor: pointer;
        }
      }
      .resource-comment-top {
        height: calc(100% - 32px);
        padding-bottom: 40px;
        box-sizing: border-box;
        overflow-y: auto;
        .comment-box {
          display: flex;
          width: 100%;
          border-bottom: 1px dashed #6f656580;
          padding-bottom: 12px;
          padding-top: 12px;
          &:first-child {
            padding-top: 0;
          }
          .user-avatar {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .user-name {
            margin-bottom: 4px;
          }
          .comment-time {
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 8px;
            font-size: 12px;
          }
        }
      }
      .resource-comment-bottom {
        width: 320px;
        height: 32px;
        position: absolute;
        bottom: 20px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comment-icon {
          margin-left: 20px;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
