<template>
  <div class="video-tool">
    <a-spin :spinning="loading">
      <a-alert
        v-if="messageData.message"
        :message="messageData.message"
        :type="messageData.type"
        show-icon
        style="width: 80%; margin: 0 auto"
      ></a-alert>
      <div class="video-box">
        <video id="videoTool" autoplay="autoplay"></video>
      </div>
      <canvas id="canvas" width="500" height="500"></canvas>
    </a-spin>
  </div>
</template>

<script>
// 注意：当前组件不是在 #app 下进行渲染，无法使用 #app 下的环境（全局组件，全局指令，原型属性函数）
import { reactive, ref, computed, nextTick, watch } from "vue";
import { toRefs } from "@vueuse/core";
import {
  insertCourseVerifyRecordData,
  submitRecordVideoUrl,
} from "@/api/main.js";
// import VideoMask from "../cameratools/videoMask.vue";
import { uploadFile } from "@/utils/ali-oss/upload.js";
import { getUserData, getStudentData } from "@/utils/storeData.js";
import { message, notification } from "ant-design-vue";

export default {
  name: "XtxConfirm",
  components: {
    // VideoMask,
  },
  props: {
    // 确认按钮
    confirmCallback: {
      type: Function,
      default: () => {},
    },
    // 取消按钮
    cancelCallback: {
      type: Function,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      default: null,
    },
    courseId: {
      default: null,
    },
    studentId: {
      default: null,
    },
    videoTime: {
      default: null,
    },
  },
  emits: ["update:visible", "success"],
  setup(props, { emit }) {
    // 点击 target 目标元素外部相当于点击了取消
    const target = ref(null);
    const pageData = reactive({
      loading: false,
      mediaStreamTrack: null,
      userData: computed(() => getUserData()).value,
      closeable: false,
      errorMessage: null,
      studentList: null,
      cameraVisible: false,
      form: {
        resource_id: props.resourceId,
        course_id: props.courseId,
        student_id: props.studentId,
        record_verify_type: "video",
        record_verify_src: null,
      },
      video_study_time: props.studyTime,
      messageData: { message: null, type: "error" },
      student_photo: null,
      videoCount: 0,
      submitDisableBtn: true,
      recordedBlobs: [],
      stream: null,
      mediaRecorder: null,
      videoTool2: null,
      videoTimeValue: null,
      videoBase64: null,
    });

    // onClickOutside(target, () => {
    //   props.cancelCallback();
    // });

    // 提示
    const noticeFun = (type, message) => {
      pageData.messageData.message = message;
      pageData.messageData.type = type;
      notification[type]({
        message: message,
        duration: 2.5,
      });
    };
    // 上传图片
    const uploadFileFun = (_img, _name) => {
      return new Promise((resolve, reject) => {
        uploadFile(
          _img,
          "index/video/",
          "video",
          _name,
          function (res) {
            resolve(res);
          },
          function (res) {
            reject(res);
          }
        );
      });
    };
    //buffer转dataUrl
    const bufferToDataUrl = () => {
      return new Promise((resolve, reject) => {
        let blob = new Blob(pageData.recordedBlobs, { type: "video/mp4" });
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          // var a = document.createElement("a");
          // a.style.display = "none";
          console.log("blob", blob);
          console.log("reader", reader.result);
          pageData.videoBase64 = reader.result;
          resolve(reader.result);
          // return reader.result;
          //文件名 通过方法传进来 检测是否合法？
          // a.download = "record.mp4";
          // document.body.appendChild(a);
          // a.click();
          // setTimeout(function () {
          // document.body.removeChild(a);
          // window.URL.revokeObjectURL(reader.result);
          // }, 100);/
        };
      });
    };
    //转文件
    const dataUrlToFile = (dataUrl, name) => {
      let binary = atob(dataUrl.split(",")[1]);
      let data = [];
      for (var i = 0; i < binary.length; i++) data.push(binary.charCodeAt(i));
      return new File([new Uint8Array(data)], name, {
        type: "video/mp4",
      });
    };
    const getSupportedMimeTypes = () => {
      const possibleType = [
        "video/mp4;codecs=h264,aac",
        "video/webm;codecs=vp9,opus",
        "video/webm;codecs=vp8,opus",
        "video/webm;codecs=h264,opus",
      ];
      return possibleType.filter((i) => MediaRecorder.isTypeSupported(i));
    };
    getSupportedMimeTypes();

    // 拍摄照片
    const takePhoto = () => {
      // pageData.recordedBlobs = [];
      let mimeType = getSupportedMimeTypes();
      var options = { mimeType: mimeType[0] };
      // if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      //   console.log(options.mimeType + "不被支持");
      //   options = { mimeType: "video/webm;codecs=vp8" };
      //   if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      //     console.log(options.mimeType + "不被支持");
      //     options = { mimeType: "video/webm" };
      //     if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      //       console.log(options.mimeType + "不被支持");
      //       options = { mimeType: "" };
      //     }
      //   }
      // }
      try {
        pageData.mediaRecorder = new MediaRecorder(pageData.stream, options);
      } catch (e) {
        message.error("创建MediaRecorder出错:" + e);
        return;
      }
      // console.log(
      //   "Created MediaRecorder",
      //   pageData.mediaRecorder,
      //   "with options",
      //   options
      // );
      pageData.mediaRecorder.onstop = () => {
        console.log("onstop");
        let _imgFileName =
          new Date().getTime() + Math.floor(Math.random() * 150) + ".mp4";
        bufferToDataUrl()
          .then((res) => {
            let file = dataUrlToFile(res, _imgFileName);
            return uploadFileFun(file, _imgFileName);
          })
          .then((res) => {
            pageData.form.record_verify_src = res;
            submitForm();
          })
          .catch((res) => {
            console.log(res);
            noticeFun("上传视频有误，请稍后再试");
          });
      };
      pageData.mediaRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          pageData.recordedBlobs.push(event.data);
        }
      };
      pageData.mediaRecorder.start(pageData.videoTimeValue); // collect 10ms of data
      setTimeout(() => {
        stopVideo();
      }, pageData.videoTimeValue);
    };
    const getStream = (stream) => {
      var video = document.getElementById("videoTool");
      console.log("stream.getTracks()", stream.getTracks());
      pageData.mediaStreamTrack = stream.getTracks();
      pageData.stream = stream;
      video.srcObject = stream;
      video.play();
      video.onloadedmetadata = function () {
        pageData.loading = false;
        // noticeFun("info", "把脸移入框内，请直视摄像头");
        setTimeout(() => {
          takePhoto();
        }, 3000);
      };
    };

    const getStreamError = (err) => {
      console.log(err.name + ": " + err.message);
      if (err.name == "NotAllowedError" && err.message == "Permission denied") {
        noticeFun("error", "请打开摄像头权限");
        Modal.error({
          title: "请打开摄像头权限！",
          okText: "确认",
          onOk() {
            window.history.back();
          },
        });
        // message.error("请授权使用摄像头");
      } else {
        Modal.error({
          title: "摄像头调取失败:" + err.message,
          onOk() {
            window.history.back();
          },
        });
        noticeFun("error", "摄像头调取失败:" + err?.name + ":" + err.message);
      }
      pageData.loading = false;
      pageData.closeable = true;
    };
    // 获取摄像头
    const getMedia = () => {
      // 得到摄像头api
      if (!navigator.mediaDevices && !navigator.mediaDevices?.getUserMedia) {
        // navigator.userMedia =
        //   navigator.mozGetUserMedia || navigator.getUserMedia;
        navigator.userMedia =
          navigator.getUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia;
        if (!navigator.userMedia) {
          // alert("浏览器不支持拍照，请更换浏览器！");
          Modal.error({
            title: "浏览器不支持拍照，请更换浏览器！",
            onOk() {
              window.history.back();
            },
          });
          return false;
        }
        navigator.userMedia(
          {
            video: { width: 500, height: 500 },
            //关闭video话筒false，开启true
            audio: false,
          },
          (stream) => getStream(stream),
          (err) => getStreamError(err)
        );
        return false;
      }

      if (navigator.mediaDevices && navigator.mediaDevices?.getUserMedia) {
        // 如果我们想要立即显示拍照，请设置 { video: true }
        navigator.mediaDevices
          .getUserMedia({
            video: { width: 500, height: 500 },
            //关闭video话筒false，开启true
            audio: false,
            // audio: {
            //   echoCancellation: true,
            //   noiseSuppression: true,
            //   sampleRate: 44100,
            // },
          })
          .then(function (stream) {
            getStream(stream);
          })
          .catch(function (err) {
            getStreamError(err);
          });
      }
    };

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          pageData.cameraVisible = true;
          pageData.loading = true;
          pageData.form.student_id = props.studentId;
          pageData.form.resource_id = props.resourceId;
          pageData.form.course_id = props.courseId;
          pageData.videoTimeValue = (props.videoTime + 1) * 1000;
          getStudentData()
            .then((res) => {
              pageData.student_photo = res.filter(
                (res) => res.student_id == props.studentId
              )[0].student_photo;
              nextTick(() => {
                getMedia();
              });
            })
            .catch((res) => {
              console.log(res);
            });
        }
      },
      { immediate: true }
    );

    const stopVideo = () => {
      //影藏录制中提
      pageData.mediaRecorder.stop();
      console.log("pageData.mediaStreamTrack", pageData.mediaStreamTrack);
      closeVideo();
    };

    const closeVideo = () => {
      pageData.mediaStreamTrack[0]?.stop();
      pageData.mediaStreamTrack[1]?.stop();
    };

    const closeModal = () => {
      closeVideo();
      pageData.messageData.message = null;
      pageData.messageData.type = "success";
      props.confirmCallback();
      notification.destroy();
    };

    const insertCourseVerifyRecordDataFun = (data) => {
      return new Promise((resolve, reject) => {
        insertCourseVerifyRecordData(data)
          .then((res) => {
            if (res.code == 200) {
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch((res) => {
            console.log(res);
            reject(res);
          });
      });
    };

    //监管人脸识别上报
    const submitRecordVideoUrlFun = (data) => {
      return new Promise((resolve, reject) => {
        submitRecordVideoUrl(data)
          .then((res) => {
            if (!res) {
              resolve(true);
            } else if (res?.code == 200) {
              resolve(true);
            } else {
              reject(res);
            }
          })
          .catch((res) => {
            console.log(res);
            reject(res);
          });
      });
    };

    const submitForm = () => {
      console.log("form", pageData.form);
      insertCourseVerifyRecordDataFun({ ...pageData.form })
        .then(() => {
          let data = {
            resource_id: pageData.form.resource_id,
            student_id: pageData.form.student_id,
            record_verify_url: pageData.form.record_verify_src,
          };
          return submitRecordVideoUrlFun(data);
        })
        .then((res) => {
          console.log("res", res);
          pageData.recordedBlobs = [];
          emit("update:visible", false);
          emit("success", true);
        })
        .catch((res) => {
          console.log(res);
          // noticeFun("error", res?.msg || "发生错误，请稍后再试");
          closeVideo();
          pageData.submitDisableBtn = false;
          pageData.closeable = true;
          if (res?.code == 250) {
            Modal.error({
              title: "系统检测到班级信息已更新，请刷新页面",
              okText: "刷新页面",
              onOk() {
                window.location.reload();
              },
            });
          } else if (res?.code == 251) {
            Modal.error({
              title: "监管平台上传数据有误(Code10005)，请重试",
              onOk() {
                window.history.back();
              },
            });
          } else {
            Modal.error({
              title: res?.msg || "数据上传有误(Code10005)，请重试",
              onOk() {
                window.history.back();
              },
            });
          }
          // else {
          //   noticeFun("error", res?.msg || "发生错误，请稍后再试");
          // }
        });
    };

    return {
      target,
      ...toRefs(pageData),
      closeModal,
    };
  },
};
</script>

<style scoped lang="less">
.video-tool {
  display: none;
}
.xtx-confirm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba(0, 0, 0, 0.5);
  .wrapper {
    width: 400px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .header,
    .footer {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: #1890f1;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: right;
      .xtx-button {
        margin-left: 20px;
      }
    }
    .header {
      position: relative;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>

<style lang="less">
.step-box {
  width: 80%;
  margin: 0 auto 40px;
}
.info-box {
  margin-bottom: 40px;
}
.video-box {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  #video {
    width: 100%;
    height: 100%;
    // object-fit: fill;
  }
}
.button-box {
  text-align: center;
}
#canvas {
  position: absolute;
  top: 9999px;
}
</style>
