<template>
  <Modal
    title="人脸认证"
    v-model:visible="pageData.cameraVisible"
    :maskClosable="false"
    :width="600"
    :footer="false"
    :closable="false"
    :centered="true"
    :destroyOnClose="true"
    :getContainer="() => _id"
    :bodyStyle="{ position: 'relative', overflow: 'hidden' }"
    @cancel="closeModal"
  >
    <Spin :spinning="pageData.loading">
      <Alert
        v-if="pageData.messageData.message"
        :message="pageData.messageData.message"
        :type="pageData.messageData.type"
        show-icon
        style="width: 80%; margin: 0 auto"
      ></Alert>
      <div class="video-box">
        <div class="video-mask">
          <video-mask></video-mask>
        </div>
        <video id="video" autoplay="autoplay"></video>
      </div>
      <div class="button-box">
        <Space>
          <!-- <a-button
            type="primary"
            size="large"
            :disabled="submitDisableBtn"
            @click="submitForm()"
            >确定</a-button
          > -->
          <Button
            v-if="pageData.closeable && pageData.submitDisableBtn"
            type="primary"
            size="large"
            @click="goLast()"
            >重新认证</Button
          >
        </Space>
      </div>
      <canvas id="canvas" width="500" height="500"></canvas>
    </Spin>
  </Modal>
</template>

<script setup>
// 注意：当前组件不是在 #app 下进行渲染，无法使用 #app 下的环境（全局组件，全局指令，原型属性函数）
import {
  reactive,
  ref,
  computed,
  nextTick,
  watch,
  defineExpose,
  defineProps,
} from "vue";
import { onClickOutside, toRefs } from "@vueuse/core";
import {
  faceDetect,
  faceMatch,
  insertCourseVerifyRecordData,
  thirdFaceCompare,
} from "@/api/main.js";
import VideoMask from "../cameratools/videoMask.vue";
import { uploadFile } from "@/utils/ali-oss/upload.js";
import {
  Button,
  Modal,
  Spin,
  Alert,
  Space,
  message,
  notification,
} from "ant-design-vue";
import { getUserData, getStudentData } from "@/utils/storeData.js";

const emit = defineEmits(["confirmcallback", "cancelcallback"]);

const props = defineProps({
  // 确认按钮
  onConfirmcallback: {
    type: Function,
    default: () => {},
  },
  // 取消按钮
  onCancelcallback: {
    type: Function,
    default: () => {},
  },
  visible: {
    type: Boolean,
    default: false,
  },
  resourceId: {
    default: null,
  },
  courseId: {
    default: null,
  },
  studentId: {
    default: null,
  },
  studyTime: {
    default: null,
  },
});

const pageData = reactive({
  loading: false,
  mediaStreamTrack: null,
  userData: computed(() => getUserData()).value,
  closeable: false,
  errorMessage: null,
  studentList: null,
  cameraVisible: false,
  form: {
    resource_id: null,
    course_id: null,
    student_id: null,
    record_verify_type: "photo",
    record_verify_src: null,
  },
  video_study_time: props.studyTime,
  messageData: { message: null, type: "error" },
  student_photo: null,
  videoCount: 0,
  submitDisableBtn: true,
  timer: null,
});
const _id = document.getElementById("MESSAGE-ID");
// onClickOutside(target, () => {
//   props.cancelcallback();
// });

// 提示
const noticeFun = (type, message) => {
  pageData.messageData.message = message;
  pageData.messageData.type = type;
  notification[type]({
    message: message,
    duration: 2.5,
  });
};
// base64转为file
const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
// 上传图片
const uploadFileFun = (_img, _name) => {
  return new Promise((resolve, reject) => {
    uploadFile(
      _img,
      "index/course/",
      "image",
      _name,
      function (res) {
        resolve(res);
      },
      function (res) {
        reject(res);
      }
    );
  });
};
// 人脸对比
const faceMatchFun = (_token) => {
  return new Promise((resolve, reject) => {
    faceMatch({
      image_1: pageData.student_photo,
      image_type_1: "URL",
      image_2: _token,
      image_type_2: "FACE_TOKEN",
    })
      .then((res) => {
        if (res.code == 200) {
          resolve(true);
        } else {
          reject(res);
        }
      })
      .catch((res) => {
        console.log(res);
        reject(res);
      });
  });
};
// 人脸检测
const faceDetectFun = (_img) => {
  return new Promise((resolve, reject) => {
    faceDetect({ image: _img, imageType: "BASE64" })
      .then((res) => {
        if (res.code == 200) {
          if (res.data.face_token) {
            resolve(res.data.face_token);
          } else {
            reject({ msg: "face_token不存在" });
          }
        } else {
          reject(res);
        }
      })
      .catch((res) => {
        console.log(res);
        reject(res);
      });
  });
};
// 拍摄照片
const takePhoto = () => {
  if (pageData.cameraVisible) {
    //获得Canvas对象
    pageData.closeable = false;
    pageData.submitDisableBtn = false;
    let video = document.getElementById("video");
    let canvas = document.getElementById("canvas");
    let ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, 500, 500);
    let url = canvas.toDataURL("image/jpg");
    if (url) {
      let img = url.replace("data:image/png;base64,", "");
      faceDetectFun(img)
        .then((res) => {
          return faceMatchFun(res);
        })
        .then(() => {
          let _imgFileName =
            new Date().getTime() + Math.floor(Math.random() * 150) + ".jpg";
          let _imgFile = dataURLtoFile(url, _imgFileName);
          return uploadFileFun(_imgFile, _imgFileName);
        })
        .then((res) => {
          pageData.form.record_verify_src = res;
          noticeFun("success", "人脸核验成功");
          // pageData.submitDisableBtn = false;
          submitForm();
        })
        .catch((res) => {
          console.log(res);
          // playAudio(res?.msg || "发生错误，请稍后再试");
          noticeFun("error", res?.msg || "发生错误，请稍后再试");
          pageData.videoCount++;
          url = null;
          // 核验人脸10次显示关闭弹窗
          if (pageData.videoCount > 5) {
            // playAudio("认证次数过多，请稍后再试");
            noticeFun("error", "认证次数过多，请稍后再试");
            closeVideo();
            pageData.closeable = true;
            pageData.submitDisableBtn = true;
            return false;
          } else {
            pageData.timer = setTimeout(() => {
              if (pageData.cameraVisible) {
                playAudio("正在拍摄，请勿眨眼");
                pageData.messageData.message = "正在拍摄，请勿眨眼";
                pageData.messageData.type = "warning";
                notification["warning"]({
                  message: "正在拍摄，请勿眨眼",
                  duration: 1,
                  onClose() {
                    takePhoto();
                  },
                });
              }
            }, 1000);
          }
        });
      // }
    }
  }
};
const playAudio = (text) => {
  var speech = new SpeechSynthesisUtterance();
  // 播放
  // speech.pitch = 1 // 获取并设置话语的音调(值越大越尖锐,越低越低沉)
  // speech.rate  = 5 // 获取并设置说话的速度(值越大语速越快,越小语速越慢)
  // speech.voice = 10 // 获取并设置说话的声音
  // speech.volume = 1 // 获取并设置说话的音量
  // speech.lang = speechSynthesis.getVoices()[0] // 设置播放语言，测试没效果
  // speech.cancel() // 删除队列中所有的语音.如果正在播放,则直接停止
  speech.lang = "zh-CN";
  speech.text = text; // 获取并设置说话时的文本
  speechSynthesis.speak(speech);
};
const getStream = (stream) => {
  var video = document.getElementById("video");
  pageData.mediaStreamTrack = stream.getTracks()[0];
  video.srcObject = stream;
  video.onloadedmetadata = function () {
    video.play();
    pageData.loading = false;
    noticeFun("info", "把脸移入框内，请直视摄像头");
    setTimeout(() => {
      pageData.messageData.message = "正在拍摄，请勿眨眼";
      playAudio("正在拍摄，请勿眨眼");
      pageData.messageData.type = "warning";
      notification["warning"]({
        message: "正在拍摄，请勿眨眼",
        duration: 1,
        onClose() {
          takePhoto();
        },
      });
    }, 1000);
  };
};
const getStreamError = (err) => {
  console.log(err.name + ": " + err.message);
  if (err.name == "NotAllowedError" && err.message == "Permission denied") {
    noticeFun("error", "请打开摄像头权限");
    // message.error("请授权使用摄像头");
  } else {
    noticeFun("error", "摄像头调取失败:" + err?.name + ":" + err.message);
  }
  pageData.loading = false;
  pageData.closeable = true;
};
// 获取摄像头
const getMedia = () => {
  // 得到摄像头api
  if (!navigator.mediaDevices && !navigator.mediaDevices?.getUserMedia) {
    navigator.userMedia =
      navigator.getUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      navigator.oGetUserMedia;
    if (!navigator.userMedia) {
      // alert("浏览器不支持拍照，请更换浏览器！");
      Modal.error({
        title: "浏览器不支持拍照，请更换浏览器！",
        onOk() {
          window.history.back();
        },
      });
      return false;
    }
    navigator.userMedia(
      {
        video: { width: 500, height: 500 },
        //关闭video话筒false，开启true
        audio: false,
      },
      (stream) => getStream(stream),
      (err) => getStreamError(err)
    );
    return false;
  }

  if (navigator.mediaDevices && navigator.mediaDevices?.getUserMedia) {
    // 如果我们想要立即显示拍照，请设置 { video: true }
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 500, height: 500 },
        //关闭video话筒false，开启true
        audio: false,
      })
      .then(function (stream) {
        getStream(stream);
      })
      .catch(function (err) {
        getStreamError(err);
      });
  }
};

const open = (_props) => {
  pageData.cameraVisible = true;
  pageData.loading = true;
  // pageData.confirmCallback = _props.confirmCallback();
  pageData.form.student_id = _props.studentId;
  pageData.form.resource_id = _props.resourceId;
  pageData.form.course_id = _props.courseId;
  getStudentData()
    .then((res) => {
      pageData.student_photo = res.filter(
        (res) => res.student_id == _props.studentId
      )[0].student_photo;
      nextTick(() => {
        getMedia();
      });
    })
    .catch((res) => {
      console.log(res);
    });
};

const closeVideo = () => {
  pageData.mediaStreamTrack?.stop();
};

const insertCourseVerifyRecordDataFun = (data) => {
  return new Promise((resolve, reject) => {
    insertCourseVerifyRecordData(data)
      .then((res) => {
        if (res.code == 200) {
          resolve(true);
        } else {
          reject(res);
        }
      })
      .catch((res) => {
        console.log(res);
        reject(res);
      });
  });
};

//监管人脸识别上报
const thirdFaceCompareFun = (data) => {
  return new Promise((resolve, reject) => {
    thirdFaceCompare(data)
      .then((res) => {
        if (!res) {
          resolve(true);
        } else if (res?.code == 200) {
          resolve(true);
        } else {
          reject(res);
        }
      })
      .catch((res) => {
        console.log(res);
        reject(res);
      });
  });
};

const submitForm = () => {
  // console.log("form", pageData.form);
  insertCourseVerifyRecordDataFun({ ...pageData.form })
    .then(() => {
      let data = {
        resource_id: pageData.form.resource_id,
        student_id: pageData.form.student_id,
        record_verify_url: pageData.form.record_verify_src,
        video_study_time: pageData.video_study_time,
      };
      return thirdFaceCompareFun(data);
    })
    .then(() => {
      emit("confirmcallback", true);
      message.success("人脸核验成功");
      closeModal();
      console.log("人脸核验成功");
    })
    .catch((res) => {
      console.log(res);
      closeVideo();
      pageData.submitDisableBtn = false;
      pageData.closeable = true;
      if (res?.code == 250) {
        Modal.error({
          title: "系统检测到班级信息已更新，请刷新页面",
          okText: "刷新页面",
          onOk() {
            window.location.reload();
          },
        });
      } else if (res?.code == 251) {
        Modal.error({
          title: "监管平台上传数据有误(Code10004)，请重试",
          onOk() {
            window.history.back();
          },
        });
      } else {
        Modal.error({
          title: res?.msg || "人脸数据上传有误，请重试",
          onOk() {
            window.history.back();
          },
        });
      }
      // else {
      //   noticeFun("error", res?.msg || "发生错误，请稍后再试");
      // }
      emit("cancelcallback", true);
    });
};

const closeModal = () => {
  console.log("closeModal");
  closeVideo();
  pageData.cameraVisible = false;
  pageData.closeable = false;
  pageData.submitDisableBtn = false;
  clearTimeout(pageData.timer);
  pageData.messageData.message = null;
  pageData.messageData.type = "success";
  notification.destroy();
  message.destroy();
  // Modal.destroyAll();
  pageData.videoCount = 0;
};

const goLast = () => {
  pageData.videoCount = 0;
  pageData.closeable = false;
  pageData.submitDisableBtn = false;
  nextTick(() => {
    getMedia();
  });
};

defineExpose({
  open,
  closeModal,
});

// return { target, ...toRefs(pageData), closeModal, submitForm, goLast };
// },
// };
</script>

<style scoped lang="less">
.xtx-confirm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba(0, 0, 0, 0.5);
  .wrapper {
    width: 400px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .header,
    .footer {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: #1890f1;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: right;
      .xtx-button {
        margin-left: 20px;
      }
    }
    .header {
      position: relative;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>

<style lang="less">
.step-box {
  width: 80%;
  margin: 0 auto 40px;
}
.info-box {
  margin-bottom: 40px;
}
.video-box {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  #video {
    width: 100%;
    height: 100%;
    // object-fit: fill;
  }
}
.button-box {
  text-align: center;
}
#canvas {
  position: absolute;
  top: 9999px;
}
</style>
